import React, { createContext, useContext, useState, useEffect } from 'react';

// Crea el contexto
const ProductContext = createContext();

// Proveedor del contexto
export const ProductProvider = ({ children }) => {
  const [product, setProduct] = useState({
    id: 1, // Asegúrate de incluir el ID aquí
    title: 'Sorteo de Honda Wave!',
    image_urls: ['https://premiosyonivaldez.com/uploads/hondawave.jpg'],
    description: '¡Obtené chances para ganar un Honda Wave!',
    price: 200.00
});

  // Carga inicial del producto desde la API
  useEffect(() => {
    fetch('https://premiosyonivaldez.com:3001/get_product/1') // 1 es el ID del producto que quieres obtener
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        return response.json();  // Asegúrate de que el backend devuelve JSON
      })
      .then((data) => {
        setProduct(data);
      })
      .catch((error) => {
        console.error('Error al obtener el producto:', error.message);
      });
  }, []);
  

  // Función para actualizar el producto y enviarlo al backend
  const updateProduct = (updatedProduct) => {
    const newProduct = {
      ...product,
      ...updatedProduct
    };
    

    // Actualiza el estado local
    setProduct(newProduct);

    // Envía los datos actualizados al servidor
    fetch('https://premiosyonivaldez.com:3001/update_product', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newProduct),
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        console.error('Error al actualizar el producto en el servidor:', data.error);
      } else {
        console.log('Producto actualizado correctamente en el servidor');
      }
    })
    .catch((error) => console.error('Error al actualizar el producto:', error));
  };

  return (
    <ProductContext.Provider value={{ product, updateProduct }}>
      {children}
    </ProductContext.Provider>
  );
};

// Hook personalizado para usar el contexto del producto
export const useProduct = () => useContext(ProductContext);
